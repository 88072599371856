export const project_menu = [
  {
    key: "01",
    title: "WhoIam",
    url: "/whoiam",
  },
  {
    key: "02",
    title: "Media",
    url: "/media",
  },
  {
    key: "03",
    title: "Answering",
    url: "/answering",
  },
  {
    key: "04",
    title: "History",
    url: "/history",
  },
];
