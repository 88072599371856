export interface SingleHistory {
  id: number;
  title: string;
  text: string;
  thumbnail: string;
  details?: string[];
}

export const historyData: SingleHistory[] = [
  {
    id: 1,
    title: "Who I am Preview",
    text: "강연기획단체 'I AM' 에서 준비한 'Who I am' 프로그램 시작 전, 모두가 자유롭게 들어볼 수 있는 프리뷰 맛보기 강연입니다. \n'I AM'이 전하는 삶의 가치, 'Who I am' 참여를 통한 내 삶의 변화된 모습을 제시합니다.",
    thumbnail: "assets/history_whoiampreview_P_2.jpg",
    details: [
      // "assets/history_whoiampreview_P_2.jpg",
      // "assets/history_whoiampreview_P_1.jpg",
      // "assets/history_whoiampreview_P_3.jpg",
      "assets/history_whoiam_preview_1.jpg",
      "assets/history_whoiam_preview_2.jpg",
      "assets/history_whoiam_preview_3.jpg",
    ],
  },
  {
    id: 2,
    title: "I AM CLASS",
    text: "과연 Chat GPT보다 내가 더 가치가 있을까..? 진짜 나를 알고 가치를 발견하는 인문학 강연",
    thumbnail: "assets/history_iamclass_P_2.jpg",
    details: [
      // "assets/history_iamclass_P_2.jpg",
      // "assets/history_iamclass_P_1.jpg",
      "assets/history_iamclass_1.jpg",
    ],
  },
  {
    id: 3,
    title: "떡 하나 주면 안 잡아 먹지",
    text: "❤️ 떡 하나 주면 안 잡아 먹지: 해와 달이 된 오누이 ver. 인생의 한 시절은 마치 산의 한 고비를 넘는 것과 같다. \n유년기, 청소년기, 청년기 ...그동안 살아온 인생의 고비를 되짚어보며 더 나은 미래의 인생을 그려볼 수 있는 시간. ",
    thumbnail: "assets/history_떡하나_P_1.jpg",
    details: [
      // "assets/history_떡하나_P_1.jpg",
      "assets/history_떡하나_1.jpg",
      "assets/history_떡하나_2.jpg",
    ],
  },
  {
    id: 4,
    title: "토끼가 탄 청룡열차",
    text: "이번 역은 검은 토끼의 해를 지나쳐 더 멋진 청룡의 해로 달려가는 열차입니다🚌💨\n다양한 체험과 찐 맛도리 사주 타로 운세까지 2024년 새해를 알차게 맞이해보세요!",
    thumbnail: "assets/history_bluedragon_P_2.jpg",
    details: [
      // "assets/history_bluedragon_P_2.jpg",
      // "assets/history_bluedragon_P_1.jpg",
      "assets/history_bluedragon_1.jpg",
      "assets/history_bluedragon_2.jpg",
    ],
  },
  {
    id: 5,
    title: "You are, I'm",
    text: "유월, 자연이 싱그러워지는 초여름에, 꽃을 보며 한숨 돌리고, 나를 돌아보는 시간",
    thumbnail: "assets/history_You'are, I'm_P_1.jpg",
    // details: ["assets/history_You'are, I'm_P_1.jpg"],
  },
  {
    id: 6,
    title: "가치의 발견",
    text: "1) 1부 : 인문학 강연\n2) 2부 : 조별 토의와 토론\n※ 주중 조별 미션",
    thumbnail: "assets/history_poster1_1.jpg",
    // details: ["assets/history_poster1_1.jpg"],
  },
  {
    id: 7,
    title: "LEADER",
    text: "4차 산업 시대 가운데 진정한 리더쉽을 발휘하려면 ? \n진짜 '나'를 알고 가치를 발견하는 인문학 강연 ",
    thumbnail: "assets/history_LEADER_P.jpg",
    // details: ["assets/history_LEADER_P.jpg"],
  },
  /*{
    id: 8,
    title: "Hello, World!",
    text: "4차산업 시대 AI와 우리 전시 \n1) AI관련 작품 및 도슨트 해설 \n2) 포토존\n3) 체험존- 질문카드 작성 및 생성형 AI 미드저니 체험",
    thumbnail: "assets/history_helloworld_P.jpg",
    details: ["assets/history_helloworld_P.jpg"],
  },*/
];
